body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	> main {
		flex-grow: 2;
		min-height: 66vh;
	}
}

h1, h2, h3, h4, h5, h6 {
	text-transform: uppercase;
}


.custom-dropdown-menu {
	button {
		@extend .btn;
		@extend .btn-link;
		padding: 0 0.25rem;
		text-transform: none;
		font-weight: 400;
	}
}

.availability {
	@extend .list-inline;
	margin-bottom: 0;
	li {
		@extend .list-inline-item;
		&:before {
			content:"";
			display: inline-block;
			width: 1.5rem;
			height: .75rem;
			margin-right: 0.5rem;
			border: solid 2px;
			margin-bottom: -1px;
		}
		&.avail:before {
			border-color: $prod-avail;
		}
		&.date:before {
			border-color: $prod-date;
		}
		&.low:before {
			border-color: $prod-low;
		}
		&.na:before {
			border-color: $prod-na;
		}
		&:not(:last-child) {
			margin-right: 1.25rem;
		}
	}
}

//datepicker overwrite
.datepicker.datepicker-dropdown {
	tfoot .today {
		display: table-cell !important;
	}
}

.form-switch {
	input {
		-webkit-appearance: none;
		-moz-appearance: none;
		min-width: 48px;
	}
}
.form-check-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	min-width: 1rem;
}

.flex-container {
	display: flex;
	flex-direction: column;
	gap: 1.4rem;
	flex-wrap: wrap;

	a {
		width: fit-content;
		margin-left: 0 !important;
	}

	@media (min-width: 1100px) {
		flex-direction: row;
		gap: 3rem;
	}
}
