.filter, #offcanvasFilter {


	.plk_filter-header {
		margin-bottom: 0;
		button {
			@extend .accordion-button;
			font-size: $font-size-base;
			font-weight: 700;
			text-transform: uppercase;
			padding-left: 0;
			box-shadow: none !important;
			&:hover {
				color: $blue-prime;
				border-bottom: solid 1px $grey-filter;
				&:after {
					background-image: escape-svg($accordion-button-hover-icon);
				}
			}
			&:focus {
				border-bottom: solid 1px $grey-filter;
			}
			&:not(.collapsed) {
				border-bottom: 0;
				&:after {
					background-image: escape-svg($accordion-button-hover-active-icon);
				}
			}
			border-bottom: solid 1px $grey-filter;
		}
		
	}
	.filter-select {
		margin-bottom: 1.5rem;
		> span {
			display: inline-block;
			color: black;
			font-size: $font-size-xs;
			font-weight: 700;
			padding-bottom: 0.5rem;
		}
		> div {
			margin: -($spacer * 0.5 / 2);
		}
		.btn.btn-icon-text.btn-xs {
			margin:  ($spacer * .5)/2;
			font-weight: 700;
			padding-left: 1.75rem;
			padding-right: 0.25rem;
			svg {
				margin-left: 1rem;
			}
		}

		.btn-reset {
			display: inline-block;
			margin-top: 1rem;
			font-size: $font-size-xs;
		}
	}
	
	.plk_filter-collapse {
		@extend .accordion-collapse;
		border-bottom: solid 1px $grey-filter;
		
		> div {
			@extend .accordion-body;
			padding-top: 0;
			padding-bottom: 0;
			ul {
				@extend .list-unstyled;
			}
			li {
				input {
					position: absolute;
					height: 0;
					width: 0;
					display: none;
					&:checked + label {
						font-weight: 700;
					}
				}
				label {
					display: block;
					&:hover {
						cursor: pointer;
						color: $blue-prime;
					}
					padding: 0.25rem 1rem 0.25rem 0rem;
				}
			}
		}
	}

}
