.form-floating {  
	> .form-control,
	> .form-select {
	  font-weight: 400;
	}



	.is-invalid {
		&+ .help-block {
			&+ label {
				display: none;
			}
		}

		&+ label {
			color: $red;
		}
	}
}

.global_error_box {
	color: $red;
	border: solid 1px $red;
	background-color: rgba($red,0.1);
	padding: 1.5rem;
	margin-bottom: 1rem;
	> span {
		display: block;
	}
}