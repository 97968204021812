.ordergrid-header {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	
	margin-bottom: 0.5rem; // temp.

	.ordergrid-header-color {
		width: 100%;
		border-bottom: 1px solid $blue-prime;

		ul {
			padding-right: calc(var(--bs-gutter-x) * 0.5);
			padding-left: calc(var(--bs-gutter-x) * 0.5);
		}
	}
	.availability {
		ul {
			padding-left: 0;
		}
		flex-grow: 2;
	}
	#sizeType {
		margin-bottom: 1.25rem;
	}
	> div {
		&:not(:first-child) {
			margin-top: 1.5rem;
			margin-bottom: 0.25rem;
		}
	}
	.nav-tabs {
		border-bottom: 0;
		align-items: flex-end;
		min-height: 3.25rem;
	}
	.nav-item {
		&:not(:first-child) {
			.nav-link {
				margin-left: -1px;
			}
			
		}
	}
	.nav-link {
		font-size: $font-size-base;
		font-weight: 500;
		color: $blue-prime;
		border-width: 1px;
		border-color:  $blue-prime;
		margin-bottom: -1px;
		
		padding: 0.25rem 1rem;
		transition: 0.25s all ease;
		&.active, &.active:hover, &[aria-selected="true"] {
			border-color:  $blue-prime;
			background-color:  $blue-prime;
			color: $blue-prime;
			color: #fff;
		}
		&:hover {
			border-color:  $blue-prime;
			color: $black;
		}
		&:first-child {
			margin-left: -1px;
		}
	}
	a.dropdown-toggle {
		text-decoration: none;
		
	}

	#category_limit .dropdown-menu {
		.active {
			color: $blue-prime;
		}
	}
	@include media-breakpoint-down($navigation-breakpoint) {
		flex-direction: column;
		align-items: flex-start;
		> div {
			&:not(:first-child) {
				margin-top: 0;
				margin-bottom: 1.5rem;
			}
			margin-bottom: 1.5rem;
		}
	}
}


.ordergrid {
	width: 100%;
	&.tab-pane {
			display: none;
			&.active {
				display: block;
			}
	}
}
.ordergrid-table {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	border-bottom: 1px solid $grey-filter;

	@include media-breakpoint-down($navigation-breakpoint) {
		flex-direction: column;
	}	
	&:not(:first-child) {
		div h6 {
			visibility: hidden;
			@include media-breakpoint-down($navigation-breakpoint) {
				visibility: visible;
			}			
		}
	}

	> div {
		width: 200px;
		margin: 1rem 0;
		h6 {
			margin-top: .75rem;
		}
		> div {
			margin-top: 3.25rem;
			> span {
				display: block;
				&:nth-child(1) {
					font-size: $font-size-xs;
				}
				&:nth-child(2) {
					color: $blue-prime;
					font-weight: 700;
				}				
			}
		}
		@include media-breakpoint-down($navigation-breakpoint) {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			width: 100%;
			h6 {
				margin-top: 0rem;
			}
			> div {
				margin-top: 0;
				display: flex;
				align-items: baseline;
				> span {
					
					&:nth-child(1) {
						margin-right: 0.5rem;
					}			
				}
			}

		}		
	}

		> ul {
			@extend .list-inline;
			margin-bottom: 0;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			flex-grow: 2;
			margin: 1rem 0;
		}
		li {
			@extend .list-inline-item;
			width: 80px;
			text-align: center;
			margin: 0 !important;
			

			> div {
					margin: 0.75rem 0;
			}
		}

		.size {
			font-weight: 700;
		}
		.price {
			margin-bottom: 1.5rem;
		}
		input {
			width: 50px;
			margin: 0 auto;
			&.avail {
				border-color: $prod-avail;
			}
			&.date {
				border-color: $prod-date;
			}
			&.low {
				border-color: $prod-low;
			}
			&.na {
				border-color: $prod-na;
			}
		}
		.date {
			font-weight: 500;
		}

}

.ordertable, .ordertable_cart {

	.ordertable-item {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid $grey-filter;
		padding: 2rem 0;
		.ordertable-info {
			width: 200px;
		}

		@include media-breakpoint-down($navigation-breakpoint) {
			flex-direction: column;
			.ordertable-info {
				width: 100%;
		
			}
		}
	
			&[data-item="reference"] {
				display: none;
			}
	}
	.ordertable-info {
		> img {
			width: 160px;
			@include media-breakpoint-down($navigation-breakpoint) {
				width: 100px;
				float: left;
				margin-right: 1rem;
			}
		}
		h5 {
			font-size: $font-size-base;
			text-transform: inherit;
			color: #000;
			margin-bottom: 0;
			margin-top: 2rem;
		}
		h5 + a {
			font-size: $font-size-sm;
			margin: 1rem 0;
    		display: block;
			color:  $blue-prime;
		}

		[data-latest] {
			font-weight: 700;
		}
		.partialdelivery {
			display: none;
		}
	}

	.ordertable-footer {
		width: 100%;
		text-align: right;
		margin: 2rem 0;
		padding: 0.5rem 2.5rem 2.5rem 0;

		.summary {
			font-size: $font-size-lg;
			margin-bottom: 2.5rem;

			&_quantity {
				margin-right: 1rem;
			}
			&_price {
				font-weight: 700;
			}
		}

		&.empty {
			border-top: 0;
			padding: 0;
			.summary {
				display: none;
			}
		}
	}

	.ordertable-list {
		flex-grow: 2;
		.ordertable-partial {
			padding: 0  0 1rem  1rem;
			font-size: $font-size-base;
			font-weight: 700;
			display: flex;
		}
		table {
			width: 100%;
			table-layout: auto;
			thead {
				font-weight: 700;
				
			}
			thead, tbody {
				display: flex;
				flex-direction: column;		
			}
			td, th {
				font-size: $font-size-sm;
				width: 10%;
				min-width: 10em;
				padding-right: 2rem;
				text-align: center;
				vertical-align: top;
				&:nth-child(4) {
					width:	100%;
					text-align: right;
					padding-right: 1rem;
					}
					&:nth-child(5) {
					width:	1%;
					min-width: 1em;
					text-align: right;
					padding-right: 0;
					}

				> span {
					min-width: 1.5em;
					text-align: right;
					display: inline-block;
				}
			}
			[data-action="edit"] {
				margin: 0 1.25em 0 0.5rem;
			}
			.btn-reset {
				color: $blue-prime;
				svg {
					position: relative;
					margin-top: -2px;
					width: 1rem;
					height: 1rem;
				}
				&:hover {
					color: #000;
				}
			}

			@include media-breakpoint-down($navigation-breakpoint) {
				td, th {
					width: 10%;
					min-width: 5em;
					padding-right: .25rem;
					&:nth-child(4) {
						width:	inherit;
					}
				}
			}
		}
		
	}

}

.ordertable_cart {
	.ordertable-info {
		min-width: 40%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		p, h3 {
			margin-bottom: 0;
		}
		.panel-title {
			color: black;
			font-size: $font-size-base;
		}
		div + div {
			margin-left: 2rem;
		}
		a {
			display: block;
		}
		button {
			font-size: $font-size-sm;
		}
		.ordertable-info-text {
			display: flex;

			a img {
				max-width: 125px;
			}
		}
		.ordertable-info-partial {
			margin-top: 1rem;
			margin-left: 0;
			font-size: $font-size-sm;
			form {
				margin: 1rem 0;
			}
		}

	}
	.ordertable-list table {
		th {
			color: $blue-prime;
		}
		td[data-item="price"] strong {
			font-weight: 400;
		}
	} 
	.ordertable-footer {
		padding: 2rem 2rem 1rem 0;
		margin: 0;
		font-size: 10px;
		.summary {
			margin-bottom: 0;
			font-size: $font-size-base;
		}
	}
}