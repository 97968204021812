

.itemlist-footer {
	.itemlist-total {
		font-size: $font-size-sm;
		font-weight: 700;
		@include media-breakpoint-up(md) {
			font-size: $font-size-base;
		}
		> div {
			@extend .text-end;
		}
	}
}

.categories .itemlist.tiles.wishlist {
	width: auto;
	max-width: inherit;
}