.checkout {
	
}


.payment {
	.shipping_box {
		label {
			font-weight: 700;
		}
	}
	#shippingAddressList {
		.content_box {
			height: 100%;
		}
		address {
			margin: 0;
		}
	}

	.content_box {
		padding-right: 2rem;
	}
}

.checkorder {
	#sendOrderForm {
		.row {
			align-items: stretch;
			.panel {
				//height: 100%;
				display: flex;
				flex-direction: column;
				align-items: stretch;
					.panel-body {
						height: 100%;
						flex-grow: 2;
						margin-bottom: 1rem;
						.content_box {
							height: 100%;
						}
					}
			}
		}
	}
}

.cart {
	.panel + .itemlist-total {
		padding-top: 2.5rem;
		margin-top: 2.5rem;
		//border-top: solid 1px $grey;
	}

	> .row {
		padding-right: 2rem;
	}
	> div:last-child {
		margin-top: 2.5rem;
	}
}


.panel-cart {
	display: flex;
	width: 100%;
	.panel-heading {
		min-width: 40%;
	}
	.cartpage {
		flex-grow: 2;
	}
}


.itemlist {
	&-total {
		padding: 2rem 1rem 1rem 0;

		font-size: $font-size-base;
		font-weight: 700;
		@include media-breakpoint-up(md) {
			font-size: $font-size-lg;
		}
		> div {
			@extend .text-end;
		}

		+ .itemlist-total {
			padding-top: 0;
		}
	}
	&-subtotal {

	}
}