.content_box {
	display: block;
	position: relative;
	background-color: $grey-light;
	border: solid 1px $grey-light;
	padding: 2rem;
	text-decoration: none;
	padding-right: 4rem;
	svg {
		position: absolute;
		top: calc(50% - (2.5rem / 2));
		right: 1rem;
		width: 2rem;
		height: 2.5rem;
	}

	h3 {
		margin-bottom: 0;
		text-transform: inherit;
		font-weight: 300;
		color: inherit;
	}
	&+.content_box {
		margin-top: 2rem;
	}

	&-equal {
		height: 100%;
	}

	&-alternate {
		background-color: transparent;
		border: solid 1px $table-line;
	}
	&-strong {
		font-weight: 700;
	}
}