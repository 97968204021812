.checkoutheader {
	margin-bottom: 1rem;
	@include media-breakpoint-up($navigation-breakpoint) {
		margin-bottom: 3rem;
	}	
	li {
		@extend .breadcrumb-item;
		font-weight: 300;
		text-transform: uppercase;
		a {
			text-transform: none;
			font-size: $h2-font-size;
			font-weight: 700;
			color: var(--bs-blue-prime);
			text-decoration: none;

			
		}
		&.disabled {
			a {
				color: var(--bs-blue-grey);
				pointer-events: none;
			}
		}
	}
	li + .breadcrumb-item::before,
	.breadcrumb-item + li:before,
	li + li::before {
		display: inline-block;
		width: 1.5rem;
		height: 100%;
		padding-right: 0;
		margin: 0 1rem;
		content: '';
		background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23004c97' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
		background-repeat: no-repeat;
		background-position: center;
	}
	li + li.disabled:before {
		background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%2390a8c0' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

	}
}
