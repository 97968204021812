.prod_images {
	display: flex;
	flex-direction: column;
	margin-top: 3rem;
	margin-bottom: 4rem;
	@include make-col-ready();
	@include make-col(12);
	@include media-breakpoint-up(md) {
		@include make-col(5);
		width: 532px;
		flex-direction: row;
	}
	
	.prod_thumbs {
		width: 75px;
		margin-right: 2rem;

		figure {
			width: 75px;
			a {
				display: flex;
				height: 100%;
				width: 100%;
			}
		}
	}
	.prod_figure {
		flex-grow: 1;
	}

	.prod_image {
		flex-grow: 2;
		margin-left: 1.25rem;
		figure {
			width: 100%;
			max-width: 393px;
			@include media-breakpoint-up(md) {
				width: 393px;
				max-width: inherit;
			}
			.item {
				opacity : 0;
				transition: opacity 0.5s ease-in-out;
				&.show {
					opacity: 1;
					transition: opacity 0.5s ease-in-out;
					z-index: 1;
				}
			}
		}
	}
	figure {
		@extend .ratio;
		@extend .ratio-1x1;
		flex-grow: 0;
		margin-bottom: 0;
		width: 100%;
		> div, .item {
			width: 100%;
			text-align: center;
			margin-bottom: 0.5rem;
			display: flex;
			 img {
				max-width: 100%;
				max-height: 100%;
				padding: 0.5rem 0;
				margin: auto;
			}
			
		}
	}

	.tile_badges {
		display: flex;
		z-index: 2;
		padding: 0 0.75rem;
		width: 8.25rem;
		height: auto;
		justify-content: space-between;
		position: absolute;
		.label {
			height: 7.5rem;
			width: 7.5rem;
			> div {
				width: 100%;
				height: 100%;
				text-align: center;
				display: flex;
				> img {
					justify-content: center;
					max-width: 100%;
					max-height: 100%;
					margin: auto;
				}
			}
		}
	}	
}

.prod_details {
	@include make-col-ready();
	@include make-col(12);
	
	display: flex;
	flex-direction: column;
	margin-bottom: 4rem;
	h1 {
		font-size: $h2-font-size;
		margin-bottom: 0;
		color: inherit;
    	text-transform: inherit;
	}
	h1 + p {
		margin-bottom: 2.75rem;
	}
	.dl-horizontal {
		font-size: $font-size-sm;
		display: grid;
		grid-template: auto/1fr 10fr;
		dt {
		  grid-column: 1;
		  margin-right: 1rem;
		  white-space: nowrap;
		}
		dd {
		  grid-column: 2;
		  margin-bottom: 0;
		}
	}

	.prices {
		margin-top: 2rem;
		font-size: $font-size-lg;
	}

	@include media-breakpoint-up(md) {
		
		@include make-col();
		.dl-horizontal {
			font-size: $font-size-base;
		}

	}

	.addToWishlist {
		position: relative;
		.hidden {
			display: none;
		}
		.alert-success {
			position: absolute;
			left: 0;
			z-index: 100;
			min-width: 175px;
		}
		.btn {
			text-transform: none;
			font-size: inherit;
			font-weight: 700;
			

		}

		svg {
			height: 1rem;
			width: 1rem;
			margin-left: 0.5rem;
		}
	}
}

.prodswitcher {
	margin-bottom: 0;
	
	li:before {
		display: none;
	}
	li + li {
		margin-left: 2rem;
	}
	a {
		text-transform: none;
		text-decoration: none !important;
		font-size: $font-size-base;
		font-weight: 700;
		svg {
			margin-left: $font-size-base/4;
			width: $font-size-base;
			height: $font-size-base;

			&.icon-left {
				margin-left: 0;
				margin-right: $font-size-base/4;
			}
		}	
	}
}

.tns-outer {
	position: relative;
	width: 90%;
	max-width: 396px;
	height: 75px;
	order: 2;
	margin-left: 1rem;

	@include media-breakpoint-up(md) {
		width: 396px;
		max-width: inherit;
	}


}
.tns-controls {
    
	button {
		position: absolute;
		height: 75px;
		width: 1rem;
		padding: 0;
		margin: 0;
		border: 0;
		background: none;
		svg {
			width: 100%;
			height: 100%;
		}

	}
	[data-controls="prev"] {
		top: 0;
		left: -1rem;		
	}
	[data-controls="next"] {
		bottom: 0;
		right: -1rem;
	}
}

@include media-breakpoint-up(md) {
	.tns-outer {
		order: 0;
		position: relative;
		height: 396px;
		width: 75px;
		margin-left: 0;
	}
	.tns-controls {
		
		button {
			height: 1rem;
			width: 75px;
		}
		[data-controls="prev"] {
			top: -1rem;
			left: 0;		
		}
		[data-controls="next"] {
			bottom: -1rem;
			left: 0;
		}
	}
}