.tiles {
	// *****TODO: clean up/share code of duplicated definitions for tiles 
	.tile_list {

		position: relative;
		@include make-col-ready();

		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: space-between;
		font-size: $font-size-sm;
		font-weight: $font-weight-light;
		margin-bottom: 2rem;
	
		.title {
			font-size: $font-size-sm;
			color: var(--bs-blue-grey);
			font-weight: $font-weight-bold;
			margin: 0 0 0.5rem 0;
		}
		
				
		
		.tile_footer {
			display: flex;
			flex-direction: column;
			flex-grow: 0;
			justify-content: space-between;
			.prices {
				min-height: 2.625rem;
				margin-bottom: 0.5rem;
				> div {
					text-align: right;
				}
			}
			a {
				margin-top: auto;
			}
			.btn {
				@extend .w-100;
			}
		}

		.tile_figure {
			@extend .ratio;
			@extend .ratio-1x1;
			flex-grow: 0;
			margin-bottom: 0;
			margin-right: 1rem;
			max-width: 75px;
			> div {
				width: 100%;
				text-align: center;
				margin-bottom: 0.5rem;
				display: flex;
				> img {
					max-width: 100%;
					max-height: 100%;
					padding: 0.5rem 0;
					margin: auto;
				}
			}
		}
		.tile_details {
			flex-grow: 1;
		}

		.removeFromWishlistPage {
			position: relative;
			z-index: 1000;
			.btn {
				font-size: inherit;
				font-weight: inherit;
				text-decoration: underline !important;
			}
		}
		.tile_badges {
			display: flex;
			padding: 0rem 0.5rem 0rem 0.25rem;
			width: 100%;
			height: auto;
			justify-content: space-between;
			position: absolute;
			.label {
				height: 2.25rem;
				width: 2.25rem;
				
		
			> div {
				width: 100%;
				height: 100%;
				text-align: center;
				display: flex;
				> img {
					justify-content: center;
					max-width: 100%;
					max-height: 100%;
					margin: auto;
				}
			}
			}
			.addToWishlist {
				&.hidden {
					display: none;
				}
				.alert-success {
					position: absolute;
					left: 0;
					z-index: 100;
					min-width: 175px;
				}
			}
			.wishlist {
				height: 1rem;
				width: 1rem;

			}

			a, button {
				position: relative;
				z-index: 2;
			}
			
		}
		
	}


	
}
