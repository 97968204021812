.pager {
	font-weight: 300;
	text-align: right;
	a {
		padding: 0 0.5rem;
		text-decoration: none;
		&.active {
			font-weight: 700;	
		}

		&:last-child {
			padding-right: 0;
		}
	}
}