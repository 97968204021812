.teaser-fluid {
	display: flex;
    flex-wrap: wrap;
	width: 100%;
	margin: 2.25rem 0 7rem 0;
	.teaser {
		width: 100%;

		figure {
			position: relative;
			img {
				width: 100%;
			}
			figcaption {
				font-size: clamp(1.5rem, -0.875rem + 4.5vw, 2.75rem);
				font-weight: 500;
				position: absolute;
				top: clamp(1.75rem, -0.875rem + 4.5vw, 2.5rem);
				left: clamp(2rem, -0.875rem + 4.5vw, 3.25rem);
				color: white;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.teaser {
			width: 50%;
		}
	}
}