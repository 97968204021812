.myaccount {
	margin-bottom: 5rem;

	&-logout {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 1rem;
		p {
			margin-bottom: 0;
		}
		form {
			order: -1;
			margin-bottom: 1rem;
		}
		@include media-breakpoint-up(md) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 1.5rem;
			form {
				order: 1;
				margin-bottom: 0;
				
			}
		}
		
	}
	


}