.search-input {
	margin-left: auto;

	.input-group {
		input {
			border-right: 0;
			border-color: black;
			box-shadow: none !important;
		}
		button {
			transition:  0.25s all ease;
			background-color: white !important;
			border-color: black;
			color: black !important;
			border-left: 0;
			padding: 0 .5rem;
			box-shadow: none !important;
			svg {
				transition:  0.25s all ease;
				width: 1.5rem;
				height: 1.5rem;
			}
		}

		input:focus + button {
			transition:  0.15s all ease;
			padding: 0.25rem .75rem;
			svg {
				transition:  0.15s all ease;
				width: 1rem;
				height: 1rem;
			}
		}
	}
}