#navigation {
	margin: 3.25rem 0 1rem 0;
	.navbar-nav {
		width: 100%;
		flex-direction: row;

		> .nav-item  {
			text-transform: uppercase;

			.nav-link {
				padding-left: 0.75rem;
				padding-right: 0.75rem;
				&:after {
					display: none;
				}
			}
			&.selected a {
				color: var(--bs-blue-prime);
			}

			&:first-child{
				.nav-link {
					padding-left: 0;
				}
			}
		}
	}
	.nav-item.dropdown {
		position:static!important;
	}
	.dropdown-menu   {
		padding: 1rem;
		background-color: var(--bs-grey-light);
		left:0;
		right:0;
		width:100%;
		margin-top: -0.5rem;
		border: 0;
		> ul {

			&:before {
				z-index: -1;
				display: block;
				content: '';
				position: absolute;
				left: 50%;
				right: 50%;
				top: 0;
				margin-left: -50vw;
				margin-right: -50vw;
				width: 100vw;
				height: 100%;
				background-color: var(--bs-grey-light);
			}
			@extend .list-inline;
			column-count: 4;
			margin: 4rem 33% 4rem 0; 
			
			> li {
				@extend .nav-item;
				text-transform: none;
				> a {
					@extend .nav-link;
					font-size: $font-size-base;
					padding: 0.33rem 0;	
				}
				&.selected a {
					color: var(--bs-blue-prime);
				}
			}
		}
	}

	@include media-breakpoint-down($navigation-breakpoint) {
		display: none;

	}
}

#page-login #navigation {
	visibility: hidden;
}
