.prices {
	span {
		min-width: 3em;
		display: inline-block;
	}
	.prices_hek {
		color: var(--bs-blue-prime);
		font-weight: $font-weight-bold;
	}
	.prices_vk {
		color: var(--bs-blue-grey);		
		font-weight: $font-weight-bold;

	}
	.prices_old {
		color: black;
		text-decoration: line-through;
	}

}