.toolbar {
	flex-grow: 0;
	white-space: nowrap;
	> ul {
		@extend .list-inline;
		margin-bottom: 0;
		@include media-breakpoint-down($navigation-breakpoint) {
			line-height: 0;
		}
		> li {
			@extend .list-inline-item;
			> a {
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				span {
					position: relative;
				}
				svg {
					width: 2rem;
					height: 2rem;
				}
				.badge {
					position: absolute;
					display: flex;
					justify-content: center;
					align-items: center;
					top: 0;
					right: -0.5rem;
					border-radius: 50%;
					width: 1rem;
					height: 1rem;
					background-color: var(--bs-blue-prime);;
					color: white;
					font-size: $font-size-xxs;
					padding: 0;
					svg {
						width: 0.9rem;
						height: 0.9rem;
					}
				}
	
				.toolbar_text {
					font-size: $font-size-xs;
					text-transform: uppercase;
					margin-top: 0.5rem;
					@include media-breakpoint-down($navigation-breakpoint) {
						display: none;
					}
				}
			}
		}
		li:not(:last-child) {
			margin-right: 1.5rem;
		}
	}
	
	.toolbar_lang {
		
		@include media-breakpoint-down($navigation-breakpoint) {
			display: none;
		}
		.dropdown-toggle {
			&:after {
				display: none;
			}
		}
		.selected {
			color: var(--bs-blue-prime);
		}
		.dropdown-menu   {
			background-color: var(--bs-grey-light);
			border-color: var(--bs-grey-light);
			padding: 1rem;
			ul {
				@extend .list-unstyled;
			}
			li {
				padding: 0.33rem 0;
			}
		}
	}
}