$enable-shadows: false;

$red:     	#ff0000;
$grey-light:#f5f5f5;
$blue-prime:#004c97;
$blue-grey: #90a8c0;
$steel-grey:#6e828a;
$grey:    	#c0c0c0;
$blue-second:#455261;
$black:#000;
$grey-filter:#d4d4d4;


$prod-avail: #7cc299;
$prod-date: #93bde3;
$prod-low: #d0bd7e;
$prod-na: #d2d2d2;
$table-line: #979797;

$colors: (
  "red":       	$red,
  "grey-light": $grey-light,
  "blue-prime": $blue-prime,
  "blue-grey":  $blue-grey,
  "steel-grey": $steel-grey,
  "grey":     	$grey,
  "blue-second":$blue-second
);
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;
$body-bg:                   white;

$primary:       $blue-prime;
$secondary:     white;

$font-family-sans-serif:      "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1rem;
$font-size-xs:                $font-size-base * .75;
$font-size-xxs:                $font-size-base * .5;
$font-size-xl:                $font-size-base * 1.5;
$font-size-lg:                $font-size-base * 1.25;
$font-size-md:                $font-size-base * 1.125;
$font-size-sm:                $font-size-base * 0.875;

$h1-font-size:                $font-size-base * 1.875;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-font-weight:        700;
$headings-color:              $blue-prime;

$headings-margin-bottom:      $spacer * 1.5 !default;

$link-color:      black;
$link-hover-color:  $blue-prime;

$nav-link-font-size:                $font-size-base * 1.125;
$nav-link-font-weight:              300;
$nav-link-color:                    black;
$nav-link-hover-color:              $blue-prime;

$border-width: 2px;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$component-active-color:      #fff;

$btn-font-size-xs:      $font-size-xs;
$btn-padding-x:      1.75rem;
$btn-padding-y-xs:      .2rem;
$btn-padding-x-xs:      .33rem;

$btn-border-radius:           0;
$btn-border-radius-sm:        0;
$btn-border-radius-lg:        0;
$btn-border-radius-xs:      $btn-border-radius-sm;

$input-color:                           $black;
$input-btn-font-size:         $font-size-xs;
$input-font-weight: 300;
$input-placeholder-color:               $grey;
$input-btn-focus-box-shadow: none;
$input-btn-focus-color: transparent;
$input-btn-border-width:      $border-width;
$input-border-width:                    $input-btn-border-width;
$input-height-border:                   $input-border-width * 2;

$form-floating-height:            add(3rem, $input-height-border);
$form-floating-label-transform:   scale(.66) translateY(-0.25rem) translateX(.4rem);
$form-floating-padding-y:         .75rem;

$form-check-input-border-radius:          0;
$form-check-input-checked-color:          $component-active-color;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M13.111 1.626c-.203.023-.378.114-.468.293L7.461 11.923l-4.136-4.28c-.841-.904-2.094.347-1.19 1.188l4.793 5.013a.852.852 0 001.25-.024L13.99 2.916c.571-.792-.271-1.358-.879-1.29z' fill='#{$form-check-input-checked-color}'/></svg>");


$breadcrumb-font-size:              $font-size-base;
$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding-x:         .25rem;
$breadcrumb-margin-bottom:          1rem;
$breadcrumb-bg:                     null;
$breadcrumb-divider-color:          black;
$breadcrumb-active-color:           $blue-prime;
$breadcrumb-divider:                quote("/");
$breadcrumb-divider-flipped:        $breadcrumb-divider;
$breadcrumb-border-radius:          0;


$accordion-button-active-bg: transparent;
$accordion-button-icon:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$black}'><path d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z'/></svg>");
$accordion-button-hover-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{ $blue-prime}'><path d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z'/></svg>");
$accordion-button-active-icon:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$black}'><path d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z'/></svg>");
$accordion-button-hover-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{ $blue-prime}'><path d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z'/></svg>");
$accordion-button-focus-box-shadow: none;


$table-striped-bg:           $grey-light;
$table-border-color:         $grey;
$table-bg-scale:              0;
$table-cell-padding-y:        .75rem;
$table-border-width:          1px;