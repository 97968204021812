.breadcrumb {
	margin-bottom: 1rem;
	@include media-breakpoint-up($navigation-breakpoint) {
		margin-bottom: 3rem;
	}	
	li {
		@extend .breadcrumb-item;
		font-weight: 300;
		text-transform: uppercase;
		a {
			text-decoration: none;
		}
	}
}