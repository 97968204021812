.btn-xs {	
	@include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-border-radius-sm);	
}
.btn {
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none !important;
	min-height: 34px !important;
	svg {
		margin-left: $font-size-base/4;
		width: $font-size-base;
		height: $font-size-base;
	}
	&.btn-icon-text {
		display: inline-flex;
		align-items: center;
		&.btn-xs {
			svg {
				margin-left: $font-size-base/4;
				width: $font-size-base;
				height: $font-size-base;
			}	
		}
		&.btn-lg {
			svg {
				margin-left: $font-size-lg/4;
				width: $font-size-lg;
				height: $font-size-lg;
			}	
		}
	}
	&.text-decoration-underline {
		text-decoration: underline !important;
	}
}

.btn-primary {
	&:hover {
		background-color: $blue-grey;
		border-color: $blue-grey;
	}
}

.btn-secondary {
	color: $blue-prime;
	&:hover {
	color: white;
	background-color: $blue-prime;	
	}
}

.btn-outline-secondary {
	&:hover {
		color: $blue-prime;	
	}
}

.btn-icon {
	padding: 0;
	margin: 0;
	border: 0;
	line-height: 0;

	&:hover {
		color: $link-hover-color;
	}
}

.btn-reset {
	padding: 0;
	margin: 0;
	border: 0;
	line-height: 0;
}

.btn-link {
	padding-left: 0;
	padding-right: 0;
	margin-left: 0;
	margin-right: 0;
	border-color: transparent;
	font-weight: 400;
	text-transform: inherit;
	color: $link-color;
	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration !important;
	}
}
.btn-link-invers {
	color: $link-hover-color;
	text-decoration: underline !important;

	&:hover {
		color: $link-color;
		text-decoration: underline !important;
	}
}

.btn-list {
	display: flex;
	justify-content: space-between;
}

.no-uppercase {
	text-transform: none;
}