
footer {
	background-color: var(--bs-blue-prime);
	color: white;
	a {
		font-weight: 500;
		color: white;

		text-decoration: none;
		&:hover {
			color: white;
			text-decoration: underline;
		}
	}

	h5 {
		margin-bottom: 1.25rem;
		color: white;
	}
	padding: 2.75rem 0 1.5rem;
	@include media-breakpoint-up(md) {
		padding: 4.75rem 0 3rem;
	}
	.footer_logo {
		svg {
			width: 160px;
			height: 40px;
		}
		@include make-col-ready();
		margin-bottom: 3rem;
		@include media-breakpoint-up(md) {
			@include make-col(12);
		}
		@include media-breakpoint-up(lg) {
			@include make-col(6);
		}
	}
	.footer_contact {
		@include make-col-ready();
        margin-bottom: 3rem;
		@include media-breakpoint-up(md) {
			@include make-col(12);
		}
		@include media-breakpoint-up(lg) {
			@include make-col(6);
		}
		.footer_contact_1 {
			@include make-col-ready();
			@include media-breakpoint-up(md) {
				@include make-col(7);
				padding-right: calc(var(--bs-gutter-x) * 1.5);
			}
		}
		.footer_contact_2 {
			@include make-col-ready();
			@include media-breakpoint-up(md) {
				@include make-col(5);
			}
		}
	}
	/*
	.footer_contact {
		@include make-col-ready();
		margin-bottom: 3rem;
		@include media-breakpoint-up(md) {
			@include make-col(6);
		}
		@include media-breakpoint-up(lg) {
			@include make-col(4);
			margin-bottom: 0;
		}
		@include media-breakpoint-up(xl) {
			@include make-col(3);
		}
	}
	*/
	.footer_links {
		ul {
			@extend .list-inline;
			width: 100%;
			margin: 0 -2rem;
			li {
				margin: 0 2rem;
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				gap: 2rem;
			}
		}
		a {
			font-weight: 500;
			&:first-child{
				margin-left: 0;
			}
		}
	}

	.footer_phone {
		svg {
			display: inline-block;
			width: 1.625rem;
			height: 1.625rem;
			margin-right: 1rem;
		}
		font-size: 1.5rem;
	}
}
