.alert-notice {
	@extend .px-0;
	background-color: var(--bs-gray-200);
	color: var(--bs-blue-prime);

	.btn-close {
		@extend .fs-5;
		color: var(--bs-blue-prime);
	}
}
