.logo {
	color: var(--bs-blue-prime) !important;
	width: 299px;
    height: 75px;
	@include media-breakpoint-down($navigation-breakpoint) {
		display: none;
	}
}
.logo_sm {
	color: var(--bs-blue-prime) !important;
	width: 3rem;
	height: 3rem;
	@include media-breakpoint-up($navigation-breakpoint) {
		display: none;	
	}
}