.triggers {
	@include media-breakpoint-down($navigation-breakpoint) {
		display: none;
	}


	
}
.triggers {
	ul {
		flex-direction: row;
		li + li {
			margin-left: 1rem;
		}
	}
	
	.form-switch {
		display: flex;
		align-items: center;
		padding: 0;
		> * {
			float: none;
		}
		.form-check-label {
			@include media-breakpoint-down($navigation-breakpoint) {
				min-width: 100px;

				&.form-check-left {
					text-align: right;
				}
			}


			&.form-check-left {
				order: -1;
			}
			font-size: $font-size-xs;
			font-weight: 500;
			text-transform: uppercase;
			color: var(--bs-grey);
			margin: 0 0.5rem;
		}
		.form-check-input {
			background-image: escape-svg($form-switch-checked-bg-image);
			background-color: var(--bs-blue-prime);
			border-color: var(--bs-blue-prime);
			height: 1.5rem;
			width: 3rem;
			margin: 0;
			border: 0;
			
			
		  
		}
		.form-check-input:checked ~ .form-check-right {
			color: var(--bs-blue-prime);
		}
		.form-check-input:not(:checked) ~ .form-check-left {
			color: var(--bs-blue-prime);
		}
	  }
}