.header_menu {
	flex-grow: 0;
	@include media-breakpoint-up($navigation-breakpoint) {
		display: none;
	}
	a {
		svg {
			width: 3rem;
			height: 3rem;
		}
	}
}

#menu {
	@include media-breakpoint-up($navigation-breakpoint) {
		display: none;
	}

	.search-input {
		padding: calc((var(--mm-spn-item-height) - var(--mm-spn-line-height)) / 2) var(--mm-spn-item-indent);
	}

	.triggers {
		display: block;
		.form-switch {
			justify-content: center;
			margin: 0.5rem 0 1rem 0;
		}
	}

	background: white;
	color: black;

	li {
		text-transform: uppercase;
		font-size: $font-size-xl;
		&:after {
			border: 0;
		}
	}
}