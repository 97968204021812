.tiles {
	.tile_item {

		position: relative;
		@include make-col-ready();

		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		font-size: $font-size-sm;
		font-weight: $font-weight-light;
		margin-bottom: 5rem;
	
		.title {
			font-size: $font-size-sm;
			color: var(--bs-blue-grey);
			font-weight: $font-weight-bold;
			margin: 1rem 0 0.5rem 0;
		}
		
				
		
		.tile_footer {
			display: flex;
			flex-direction: column;
			flex-grow: 2;
			justify-content: flex-end;
			.prices {
				margin-bottom: 1rem;
			}
			.btn {
				@extend .w-100;
			}
		}
	

		.tile_figure {
			@extend .ratio;
			@extend .ratio-1x1;
			flex-grow: 0;
			margin-bottom: 0;
			> div {
				width: 100%;
				text-align: center;
				margin-bottom: 0.5rem;
				display: flex;
				> img {
					max-width: 100%;
					max-height: 100%;
					padding: 0.5rem 0;
					margin: auto;
				}
			}
		}
		
		.tile_badges {
			display: flex;
			padding: 0.75rem;
			width: 100%;
			height: auto;
			justify-content: space-between;
			position: absolute;
			.label {
				height: 3.75rem;
				width: 3.75rem;	
				> div {
					width: 100%;
					height: 100%;
					text-align: center;
					display: flex;
					> img {
						justify-content: center;
						max-width: 100%;
						max-height: 100%;
						margin: auto;
					}
				}
			}
			.addToWishlist {
				.successMessage {
					position: absolute;
					width: 100%;
					top: 0;
					right: 0;
					z-index: 100;
				}
				&.hidden {
					display: none;
				}
			}
			.wishlist {
				height: 1rem;
				width: 1rem;

			}
			a, button {
				position: relative;
				z-index: 2;
			}
			
		}
	}
}
