.categories {
	h1 {
		margin-bottom: 1.5rem;
	}
	.filter {
		flex: 0 0 auto;
		width: 20%;
		min-width: 264px;
	}
	.tiles {
		flex: 0 0 auto;
		width: 80%;
		max-width: calc(100% - 264px);
		.row + .row {
			margin-top: 2.5rem;
		}
		.noitems {
			color: black;
			margin: 1.5rem 0 5rem;
			min-height: 250px;
		}		
		.tile_item {
			@include make-col(6);
			@include media-breakpoint-up(sm) {
			  @include make-col(4);
			}
			@include media-breakpoint-up(md) {
				@include make-col(3);
			  }	
			@include media-breakpoint-up(lg) {
			  @include make-col(3);
			}
			@include media-breakpoint-up(xl) {
				@include make-col(3);
			}
		}
		.tile_list {
			@include make-col(12);
			@include media-breakpoint-up(md) {
				@include make-col(6);
			}	
		}		
	}
	@include media-breakpoint-down($navigation-breakpoint) {
		.filter {
			display: none;
		}
		.tiles {
			width: 100%;
			max-width: inherit;
		}
	}

	.list_view {
		display: flex;
		justify-content: flex-end;

		svg {
			height: 1rem;
			width: 1rem;
		}
		a + a {
			margin-left: 0.5rem;
		}
		
	}

	.category_dropdown {
		display: flex;
		@include media-breakpoint-down($navigation-breakpoint) {
			flex-wrap: wrap;
			margin: 0.5rem 0;
			> div {
				margin: .25rem 0;
			}
		}
		> div:first-child {
			margin-right: 1rem;
		}
		a {
			text-decoration: none;
		}
		.dropdown-menu {
			button {
				@extend .btn;
				@extend .btn-link;
				text-decoration: none !important;
				&:hover {
					text-decoration: none !important;
				}
				padding: 0 0.25rem;
				text-transform: none;
				font-weight: 400;
			}
		}
	}

	#filter_mobile {
		text-transform: uppercase;
		font-weight: 700;
	}
}