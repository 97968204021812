.brands {
  margin-bottom: 5rem;
  ul {
    @extend .list-inline;
  //	margin: 4rem 33% 4rem 0; 
  display: grid; 
  grid-template-columns: repeat(3, minmax(50px, 120px));
  grid-column-gap: 16.88%;
  grid-row-gap: 2rem;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(4, minmax(50px, 120px));
    grid-column-gap: 11%;
    grid-row-gap: 2rem;
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(5, minmax(50px, 120px));
    grid-column-gap: 11%;
    grid-row-gap: 2.5rem;
  }	
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(6, minmax(50px, 120px));
    grid-column-gap: 8.88%;
    grid-row-gap: 3rem;
  }
    > li {
      @extend .nav-item;
      
      text-transform: none;
      > a {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}