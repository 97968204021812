$navigation-breakpoint: "lg";
$navigation-mobile-height: 4.5rem;

@import "logo";
@import "triggers";
@import "toolbar";
@import "search";
@import "navigation";
@import "menu";

header {
	border-bottom: solid 2px var(--bs-blue-prime);
	min-height: $navigation-mobile-height;
	margin: 0 0 1rem 0;
	a {
		color: black;
		text-decoration: none;
		&:hover {
			color: var(--bs-blue-prime);
		}
	}
	.header_cols {
		@extend .row;
		@extend .align-items-center;
		min-height: $navigation-mobile-height;

	}

	@include media-breakpoint-up($navigation-breakpoint) {
		padding: 2.75rem 0 0 0;
		margin-bottom: 2rem;
		min-height: auto;

		.header_cols {
			min-height: auto;
		}
	}
	
}

#page-login header {
	background-image: url(/resources/images/banner/320066-91_4.jpg);
	background-position-y: center;
	background-size: cover;
}
